.button {
  @apply inline-flex items-center justify-center rounded text-button gap-2 px-2 sm:px-3 font-bold text-center sm:h-12 h-10 cursor-pointer transition-colors tracking-px-1 uppercase;
}

.buttonPrimary {
  @apply text-white bg-primary hover:bg-primary-700;
}

.buttonSecondary {
  @apply text-primary bg-white hover:bg-fill-gray border-primary-500 border;
}

.buttonTertiary {
  @apply text-primary bg-white hover:bg-fill-gray;
}

.buttonPrimary.buttonDisabled {
  @apply text-white bg-line-gray hover:bg-line-gray;
}

.buttonSecondary.buttonDisabled {
  @apply border-line-gray text-line-gray hover:bg-white;
}

.buttonTertiary.buttonDisabled {
  @apply text-line-gray hover:bg-white;
}

.buttonFull {
  width: 100% !important;
}
