.imageViewer {
  @apply inline-block align-middle transition-all transform;
}

.overlay {
  @apply fixed inset-0 justify-center w-full h-full bg-black bg-opacity-75;
}

.contentImage {
  @apply block w-full max-w-[100vh] max-h-[65vh];
}

.zoomActions {
  @apply fixed bottom-6 md:bottom-16 left-1/2 transform -translate-x-1/2 opacity-60 hover:opacity-95 bg-primary;
}
