:root {
  --color-primary: #b20404;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: 'Fazz Neue';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/FazzNeue-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Fazz Neue';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/FazzNeue-Bold.otf') format('opentype');
}

.text-primary {
  color: #b20404 !important;
}

body {
  font-size: 0.875rem;
  font-family: 'Fazz Neue';
  @apply text-gray bg-fill-gray;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

svg {
  display: inline-block;
}

hr {
  @apply my-4 border-0 border-t border-black border-opacity-10;
}

/* hide eye reveal toggle for input[type="password"] for edge browsers */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.card {
  @apply bg-white rounded shadow;
}

/*
 * Sidebar
 */

/* .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 240px;
  background-color: #FFFFFF;
  z-index: 2;
} */

.sidebar {
  margin-left: -16px;
  margin-right: -16px;
  background-color: #ffffff;
  z-index: 999;
  padding-top: 24px;
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sidebar-footer {
  background-color: #0fa754;
  width: 240px;
  cursor: pointer;
}

.hidden-sidebar-footer {
  background-color: #0fa754;
  position: 'absolute';
  left: 0px;
  bottom: 0px;
  width: 40px;
  border-top-right-radius: 8px;
  cursor: pointer;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

/*
 * Utilities
 */

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.badge {
  padding: 8px;
  color: #ffffff;
  font-size: 1em;
  border-radius: 16px;
  font-weight: normal;
}

.processed-label {
  background-color: #f2c94c;
}

.completed-label {
  background-color: #18ba62;
}

.cell-title {
  font-weight: bold;
  color: grey;
  margin-bottom: 0px;
}

.cell-content {
  margin-bottom: 0px;
}

.table {
  table-layout: fixed;
}

.table tr td,
.table tr th {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.table.small-horizontal-padding tr td {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.truncated-content {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  max-width: 30ch;
}

.cell-subcontent {
  color: grey;
  font-size: 0.9em;
}

.btn-blue {
  background-color: #4386f1;
  color: #ffffff;
}

.btn-outline-blue {
  background-color: #ffffff;
  color: #4386f1;
  border: #4386f1 1px solid;
}

.btn-grey {
  background-color: #e0e0e0;
  color: #ffffff;
  border: #e0e0e0 1px solid;
}

.btn-grey:hover {
  background-color: #e0e0e0;
  color: #ffffff;
  border: #e0e0e0 1px solid;
}

.text-green {
  color: #18ba62;
}

.text-light-grey {
  color: #9e9e9e;
}

.text-dark-grey {
  color: #434343;
}

.text-dark-grey-important {
  color: #434343 !important;
}

.scrollbar-green::-webkit-scrollbar {
  width: 10px;
  background-color: #e0e0e0;
}

.scrollbar-green::-webkit-scrollbar-thumb {
  background: #18ba62;
  border-radius: 10px;
}

/* FORMS */
.input-icon-right {
  position: absolute;
  top: 10px;
  right: 26px;
  z-index: 2;
  width: 18px;
  height: 18px;
  color: #9e9e9e;
}

.header-menu {
  width: 240px;
  position: absolute;
  top: 58px;
  right: 0px;
  background-color: #ffffff;
  border: 1px solid #dedede;
}

.loading-bar {
  position: absolute;
  left: 0px;
}

.front-face {
  height: 100vh;
  background-color: #f5f5f5;
}

.front-face-left {
  /* background-image: url('/img/front-face-background.jpg'); */
  background-size: cover;
  height: 100vh;
}

.card-login-type {
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  border: 2px solid transparent !important;
  box-shadow: 0px 8px 16px rgba(172, 172, 171, 0.14);
  box-sizing: border-box;
  border-radius: 4px;
}

.card-login-type:hover {
  background: #f7e9e7;
  border: 2px solid var(--color-primary) !important;
}

.card-login-type .icon {
  background: #f7e9e7;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.card-login-type:hover .icon {
  background: var(--color-primary);
  color: #ffffff;
}

input.form-control.username,
input.form-control.password {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}

.input-group.username:focus-within,
.input-group.password:focus-within {
  outline: 0;
  box-shadow: 0px 0px 0px 4px rgba(0, 128, 255, 0.25);
  border-radius: 4px;
}

input.form-control.username,
input.form-control.password {
  border-left: 0;
}

input.form-control.password {
  border-right: 0;
}

input.form-control::placeholder,
input.form-control::placeholder {
  color: #e0e0e0;
}

.input-group--readOnly * {
  background-color: #e9ecef !important;
}

.input-group-text {
  background-color: transparent;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 26px;
  color: #fff;
  cursor: pointer;
  margin: 0 !important;
}

.switch--disabled {
  cursor: not-allowed;
}

.switch--disabled.is-active .switch-slider {
  @apply bg-primary-50 !important;
}

.switch--disabled .switch-slider {
  @apply bg-line-gray !important;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch-slider {
  @apply absolute inset-0 flex items-center bg-secondary-gray;
  padding-left: 26px;
  transition: 0.4s;
  border-radius: 34px;
}

.switch-slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switch.is-active .switch-slider {
  background-color: var(--color-primary);
  padding-left: 12px;
}

.switch.is-active .switch-slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Switch Small */
.switch--small {
  width: 56px;
  height: 26px;
}

.switch--small input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch--small .switch-slider:before {
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 2px;
}

.switch--small.is-active .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.filter-border {
  border-bottom: 3px solid #dee2e6;
  cursor: pointer;
}

.selected-filter-border {
  border-bottom: 3px solid var(--color-primary);
  cursor: pointer;
}

.text-grey {
  color: #9e9e9e;
}

.bg-light-grey {
  background-color: #e0e0e0;
}

.bg-grey {
  background-color: #9e9e9e;
}

.badge-primary {
  background-color: #eb6f4e;
  color: #ffffff;
}

.btn-primary {
  background-color: #eb6f4e;
  color: #ffffff;
  border-color: #eb6f4e;
}

.btn-primary:hover {
  color: #ffffff;
}

.btn-outline-primary {
  background-color: transparent;
  color: #eb6f4e;
  border-color: #eb6f4e;
}

.btn-outline-primary:hover {
  color: #eb6f4e;
}

.btn-rounded {
  border-radius: 18px;
}

.rdrDefinedRangesWrapper {
  display: none;
}

/* override react-date-range underline styling while date not selected */
.rdrDayToday .rdrDayNumber span:after {
  background: var(--color-primary) !important;
}

.hasSelectedDate .rdrInRange {
  background: #f7e9e7 !important;
}

.rdrInRange {
  background: transparent !important;
}

/* override react-date-range color when in selected date range */
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #444 !important;
}

.hasSelectedDate .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: var(--color-primary) !important;
}

/* override react-date-range underline styling while date selected */
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff !important;
}

.rdrStartEdge,
.rdrEndEdge,
.rdrSelected,
.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  border-radius: 4px !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartPreview {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndPreview {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rdrMonthAndYearWrapper {
  height: auto !important;
  padding: 0 !important;
  margin-bottom: 20px;
}

.rdrMonthAndYearPickers select {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-control:focus {
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #ced4da;
}

.floating-hover:hover {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px 0px inset;
  cursor: pointer;
}

.modal .modal-body .product-container {
  overflow-y: auto;
  max-height: 500px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item:hover {
  @apply bg-primary-50 rounded-px-4;
}

.fit-cover {
  object-fit: cover;
}

.image-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background: #f2f2f2;
  border: 1px dashed var(--color-primary);
}

.image-input:nth-child(1) {
  margin-left: 0 !important;
}

.image-input.disabled {
  opacity: 0.3;
  border: 1px dashed var(--color-primary);
  cursor: default;
}

.image-input-preview {
  position: relative;
}

.image-input-preview:nth-child(1) {
  margin-left: 0 !important;
}

.image-input-preview img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

.image-input-preview img:hover,
.image-input-preview img.featured {
  border: 2px solid var(--color-primary);
}

.image-input-preview.badge-featured {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
  background-color: #f7e9e7;
  color: var(--color-primary);
  font-size: 0.6rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0px 6px;
  border-radius: 8px;
  border: 2px solid var(--color-primary);
  text-align: center;
}

.image-input-preview.remove {
  position: absolute;
  opacity: 0.9;
  top: 5%;
  right: 5%;
  background-color: #555555;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
  padding: 0px 2px;
  border-radius: 6px;
}

.image-input-preview.remove:hover {
  opacity: 1;
}

.image-input-preview.loading {
  position: absolute;
  top: 30%;
  left: 40%;
  color: #ffffff;
}

.image-input-preview img.loading {
  opacity: 0.5;
  filter: blur(4px);
  cursor: default;
}

.image-input-preview img.loading:hover {
  border: none;
}

.image-input-preview img.error {
  cursor: default;
}

.image-input-preview img.error {
  border: none;
}

.product-edit-images-row {
  padding: 1rem 0;
  border: 1px solid #f5f5f5;
}

.product-edit-images-row.error {
  background-color: #f7e9e7;
  border: 1px solid #eb6f4e;
  color: #eb6f4e;
}

.product-edit-images-modal.error {
  color: #eb6f4e;
  margin: 0.5rem 0;
}

/* Tab Nav */
.profile-tab-nav {
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
  display: flex;
}

.profile-tab-nav .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
}

.profile-tab-nav .nav-link,
.nav-pills .show > .nav-link {
  color: #595959;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1.8px 2px 0.6px;
  border: none;
  border-radius: 4px;
}

.highlight-input-border {
  border: none !important;
  border-bottom: 2px solid var(--color-primary) !important;
  background-color: #f7e9e7 !important;
}

.material-input {
  border: none !important;
  border-radius: 0px;
  border-bottom: 1px solid #9e9e9e !important;
}

.material-input::placeholder,
.material-input-placeholder {
  color: #b9b9b9;
}

.btn-clickable-disabled:hover {
  background-color: grey !important;
}

.shadow-bordered-rounded {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 4px 8px rgb(8 35 48 / 10%);
  border-radius: 4px;
}

/* to override bootstrap popper styling */
.adminFeeSelect .dropdown-menu.show {
  transform: translate3d(0px, 49px, 0px) !important;
}

/* Snackbar theming */
.alert {
  border: 0 !important;
}

.alert-primary,
.alert-success {
  color: #29ab87 !important;
  background-color: #e0f3ee !important;
}

.alert-danger {
  color: #eb6f4e !important;
  background-color: #f7e9e7 !important;
}

.alert-info {
  color: #434343 !important;
  background-color: #f5f5f5 !important;
}

.alert-warning {
  color: #ffd300 !important;
  background-color: #fffde5 !important;
}

/* Pagination */
.pagination {
  @apply flex justify-center text-secondary-gray border-line-gray;
}

.pagination .previous {
  @apply mr-1;
}

.pagination .next {
  @apply ml-1;
}

.pagination li a {
  @apply py-2 px-4 rounded border border-line-gray transition-opacity duration-200 hover:opacity-75;
}

.pagination .active {
  @apply text-primary border-primary;
}

.card {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
