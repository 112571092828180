.dialogOverlay {
  @apply fixed inset-0 justify-center w-full h-full bg-black bg-opacity-50;
}

.dialogTitle {
  @apply relative text-title-sm text-dark-gray font-medium mb-6 flex w-full justify-between pt-4;
}

.dialogBody {
  @apply inline-block w-full max-w-lg p-6 my-8 overflow-y-auto text-left align-middle transition-all transform shadow-md rounded-md bg-white;
}
